import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import ServiceCard from '../components/ServiceCard'
import ContactComponent from '../components/ContactComponent/ContactComponent'
import NewsRoll from '../components/NewsComponents/NewsRoll'
import SEO from '../components/SEO/SEO'
import Image from 'gatsby-image'
import { PopupText } from 'react-calendly'

const calendlyBaseUrl = 'https://calendly.com/stetindadvokat/'

export const IndexPageTemplate = ({
	image,
	heading,
	about,
	services,
	featuredNews,
	isPreview,
	contactFormTitle,
	contactFormDescription,
	contactFormImage,
	contactFormInfo,
}) => (
	<>
		{!isPreview && (
			<Image
				fluid={image}
				style={{ position: 'absolute', height: 'auto', width: '100%' }}
			/>
		)}
		<section
			className="section section--gradient background-section background-image"
			style={{ backgroundImage: isPreview ? `url(${image})` : '' }}
		>
			<div className="container">
				<div className="header-div">
					<h1 className="header-text mb-6">{heading}</h1>
					<div
						className="calendly-link-services card-link"
						style={{
							width: '250px',
							padding: '12px',
							marginBottom: '24px',
							textAlign: 'center',
							border: '1px solid white',
						}}
					>
						<PopupText
							text="Book konsultasjon online"
							url={calendlyBaseUrl}
							styles={{
								color: 'white',
								display: 'block',
								width: '100%',
								height: '100%',
							}}
						/>
					</div>
				</div>
				<div className="tile is-ancestor custom-tile">
					<div className="tile is-vertical is-parent">
						<div className="tile is-child box custom-about-tile-container">
							<div className="columns is-centered is-vcentered about-columns">
								<div className="column about-column">
									<p className="title">{about.title}</p>
									<p>{about.description}</p>
									<p>
										<strong>{about.contactInfo}</strong>
									</p>
								</div>
								<div className="column has-text-centered about-column">
									<figure className="image is-1x1">
										<img
											src={
												about.portrait.childImageSharp
													? about.portrait
															.childImageSharp
															.fluid.src
													: about.portrait
											}
											alt="logo"
										/>
									</figure>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="tile is-ancestor custom-tile">
					<div className="tile is-vertical is-parent">
						<div className="tile is-child box custom-tile-container">
							<p className="title">Online konsultasjon</p>
							<div className="columns is-centered">
								<div className="column">
									<ServiceCard
										title={services.first_title}
										text={services.first_text}
										image={
											services.first_image.childImageSharp
												? services.first_image
														.childImageSharp.fluid
														.src
												: services.first_image
										}
										calendlyEventType="familierett"
									/>
								</div>
								<div className="column">
									<ServiceCard
										title={services.second_title}
										text={services.second_text}
										image={
											services.second_image
												.childImageSharp
												? services.second_image
														.childImageSharp.fluid
														.src
												: services.second_image
										}
										calendlyEventType="arbeidsrett"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				{!isPreview && (
					<div className="tile is-ancestor custom-tile">
						<div className="tile is-vertical is-parent">
							<div className="tile is-child box has-background-black custom-tile-container">
								<p className="title has-text-white">Nyheter</p>
								{featuredNews ? (
									<NewsRoll posts={featuredNews} darkMode />
								) : (
									<h1>Det er ingen nyheter akkurat nå</h1>
								)}
							</div>
						</div>
					</div>
				)}
				{!isPreview && (
					<div className="tile is-ancestor custom-tile">
						<div className="tile is-vertical is-parent">
							<div className="tile is-child box custom-contact-tile-container">
								<ContactComponent
									title={contactFormTitle}
									description={contactFormDescription}
									image={contactFormImage}
									contact={contactFormInfo}
								/>
							</div>
						</div>
					</div>
				)}
			</div>
		</section>
	</>
)

IndexPageTemplate.propTypes = {
	image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	heading: PropTypes.string,
	about: PropTypes.object,
	services: PropTypes.object,
	contactFormTitle: PropTypes.string,
	contactFormDescription: PropTypes.string,
	contactFormImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	contactFormInfo: PropTypes.string,
}

const IndexPage = ({ data }) => {
	const { frontmatter } = data.content
	const posts = data.featuredNews.edges.map((x) => x.node)
	const contactFormData = data.contactForm.nodes[0].frontmatter

	return (
		<Layout color="white">
			<SEO />
			<IndexPageTemplate
				image={
					frontmatter.image
						? frontmatter.image.childImageSharp.fluid
						: frontmatter.image
				}
				heading={frontmatter.heading}
				about={frontmatter.about}
				services={frontmatter.services}
				featuredNews={posts}
				isPreview={false}
				contactFormTitle={contactFormData.title}
				contactFormDescription={contactFormData.description}
				contactFormImage={
					contactFormData.image.childImageSharp
						? contactFormData.image.childImageSharp.fluid
						: contactFormData.image
				}
				contactFormInfo={contactFormData.contact}
			/>
		</Layout>
	)
}

IndexPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
}

export default IndexPage

export const pageQuery = graphql`
	query IndexPageTemplate {
		contactForm: allMarkdownRemark(
			filter: { fileAbsolutePath: { regex: "/(pages/kontakt)/" } }
		) {
			nodes {
				fileAbsolutePath
				frontmatter {
					image {
						childImageSharp {
							fluid(maxWidth: 2048, quality: 85) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
					title
					description
					contact
				}
			}
		}
		featuredNews: allMarkdownRemark(
			sort: { order: DESC, fields: [frontmatter___date] }
			filter: {
				frontmatter: {
					templateKey: { eq: "news-page" }
					featuredpost: { eq: true }
				}
			}
			limit: 3
		) {
			edges {
				node {
					excerpt(pruneLength: 200)
					id
					fields {
						slug
					}
					frontmatter {
						title
						date(formatString: "MMMM DD, YYYY")
						featuredpost
						featuredimage {
							childImageSharp {
								fluid(maxWidth: 2048, quality: 85) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		}
		content: markdownRemark(
			frontmatter: { templateKey: { eq: "index-page" } }
		) {
			frontmatter {
				image {
					childImageSharp {
						fluid(maxWidth: 2048, quality: 85) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heading
				about {
					title
					description
					portrait {
						childImageSharp {
							fluid(maxWidth: 2048, quality: 85) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
					contactInfo
				}
				services {
					first_image {
						childImageSharp {
							fluid(maxWidth: 2048, quality: 85) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
					first_title
					first_text
					second_image {
						childImageSharp {
							fluid(maxWidth: 2048, quality: 85) {
								...GatsbyImageSharpFluid_withWebp
							}
						}
					}
					second_title
					second_text
				}
			}
		}
	}
`
