import React from 'react';

import { PopupText } from 'react-calendly';

const ServiceCard = (props) => {
  const url = `https://calendly.com/stetindadvokat/${props.calendlyEventType}`;
  return (
    <div className='card'>
      <div className='card-image'>
        <figure className='image is-2by1'>
          <img src={props.image} alt='Placeholder' />
        </figure>
      </div>
      <div className='card-content'>
        <div className='media'>
          <div className='media-content'>
            <p className='title is-4 '>{props.title}</p>
          </div>
        </div>

        <div className='content custom-card-content'>
          {props.text}
          <br />
        </div>
      </div>
      <footer className='card-footer'>
        <a
          href={`/tjenester/${props.calendlyEventType}`}
          className='card-footer-item card-link'
        >
          Les mer
        </a>
        <div
          className='card-footer-item card-link'
          style={{ padding: '0', textAlign: 'center' }}
        >
          <PopupText
            text='Book konsultasjon online'
            url={url}
            styles={{
              color: '#000',
              display: 'block',
              width: '100%',
              height: '100%',
              padding: '12px',
            }}
          />
        </div>
      </footer>
    </div>
  );
};

export default ServiceCard;
